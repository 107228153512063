/*jshint globalstrict: true*/
/* jshint node: true */
"use strict";

// Polyfill for Array.from
// Production steps of ECMA-262, Edition 6, 22.1.2.1
if (!Array.from) {
    Array.from = (function () {
        var toStr = Object.prototype.toString;
        var isCallable = function (fn) {
            return typeof fn === 'function' || toStr.call(fn) === '[object Function]';
        };
        var toInteger = function (value) {
            var number = Number(value);
            if (isNaN(number)) { return 0; }
            if (number === 0 || !isFinite(number)) { return number; }
            return (number > 0 ? 1 : -1) * Math.floor(Math.abs(number));
        };
        var maxSafeInteger = Math.pow(2, 53) - 1;
        var toLength = function (value) {
            var len = toInteger(value);
            return Math.min(Math.max(len, 0), maxSafeInteger);
        };

        // The length property of the from method is 1.
        return function from(arrayLike/*, mapFn, thisArg */) {
            // 1. Let C be the this value.
            var C = this;

            // 2. Let items be ToObject(arrayLike).
            var items = Object(arrayLike);

            // 3. ReturnIfAbrupt(items).
            if (arrayLike == null) {
                throw new TypeError('Array.from requires an array-like object - not null or undefined');
            }

            // 4. If mapfn is undefined, then let mapping be false.
            var mapFn = arguments.length > 1 ? arguments[1] : void undefined;
            var T;
            if (typeof mapFn !== 'undefined') {
                // 5. else
                // 5. a If IsCallable(mapfn) is false, throw a TypeError exception.
                if (!isCallable(mapFn)) {
                    throw new TypeError('Array.from: when provided, the second argument must be a function');
                }

                // 5. b. If thisArg was supplied, let T be thisArg; else let T be undefined.
                if (arguments.length > 2) {
                    T = arguments[2];
                }
            }

            // 10. Let lenValue be Get(items, "length").
            // 11. Let len be ToLength(lenValue).
            var len = toLength(items.length);

            // 13. If IsConstructor(C) is true, then
            // 13. a. Let A be the result of calling the [[Construct]] internal method
            // of C with an argument list containing the single item len.
            // 14. a. Else, Let A be ArrayCreate(len).
            var A = isCallable(C) ? Object(new C(len)) : new Array(len);

            // 16. Let k be 0.
            var k = 0;
            // 17. Repeat, while k < len… (also steps a - h)
            var kValue;
            while (k < len) {
                kValue = items[k];
                if (mapFn) {
                    A[k] = typeof T === 'undefined' ? mapFn(kValue, k) : mapFn.call(T, kValue, k);
                } else {
                    A[k] = kValue;
                }
                k += 1;
            }
            // 18. Let putStatus be Put(A, "length", len, true).
            A.length = len;
            // 20. Return A.
            return A;
        };
    }());
}

/**
 * various functions needed for the plugin but can be independently used on other
 * projects
 */
class DemoModuleUtils {

    /**
     * convert plain text URL to URL links
     * Addopted from https://stackoverflow.com/a/3890175/1251343
     * @param inputText
     * @returns {*}
     */
    static linkify(inputText) {
        let replacedText, replacePattern1, replacePattern2, replacePattern3;

        //URLs starting with http://, https://, or ftp://
        replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])(?!([^<]+)?>)/gim;
        replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

        //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
        replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))(?!([^<]+)?>)/gim;
        replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

        //Change email addresses to mailto:: links.
        replacePattern3 = /(([a-zA-Z0-9\-\\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)(?!([^<]+)?>)/gim;
        replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

        return replacedText;
    }

    /**
     * checks to see if node is visible on the screen
     * @param targetNode
     */
    static isNodeVisible(targetNode) {
        // get the top padding where text is not supposed to occupy
        let paddingTop = parseInt(window.getComputedStyle(document.body, null).getPropertyValue('padding-top'));

        // these are relative to the viewport, i.e. the window
        let clientRect = targetNode.getBoundingClientRect();
        // return !(clientRect.bottom > (this.ruler.measureVisibleHeight() + window.scrollY));

        return (
            clientRect.top >= paddingTop &&
            clientRect.left >= 0 &&
            clientRect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /*or $(window).height() */
            clientRect.right <= (window.innerWidth || document.documentElement.clientWidth) /*or $(window).width() */
        );
    }

    /**
     * scrolls the target to the top of the page
     * padding top of the document body is taken into consideration so as to always maintain a
     * gap at the top of the page
     * @param targetNode
     */
    static scrollToWord(targetNode) {
        if (!DemoModuleUtils.isNodeVisible(targetNode)) {
            let paddingTop = parseInt(window.getComputedStyle(document.body, null).getPropertyValue('padding-top'));
            let scrollValue = targetNode.getBoundingClientRect().top + window.scrollY - paddingTop;

            console.log('Scroll to: ' + scrollValue);
            window.scrollTo(0, scrollValue);

            // targetNode.scrollIntoView();
        }
    }

    static globalReplaceWord(target, replacement) {
        let targetNodes = document.querySelectorAll('[data-search-word="' + target.toLowerCase() + '"]');
        // for (let targetNode of targetNodes) {
        for (let i = 0; i < targetNodes.length; i++) {
            let targetNode = targetNodes[i];
            targetNode.textContent = replacement;
        }

        // @todo find better way to handle apostrophe s
        targetNodes = document.querySelectorAll('[data-search-word="' + target.toLowerCase() + "'s" + '"]');
        // for (let targetNode of targetNodes) {
        for (let i = 0; i < targetNodes.length; i++) {
            let targetNode = targetNodes[i];
            targetNode.textContent = replacement + "'s";
        }

        targetNodes = document.querySelectorAll('[data-search-word="' + target.toLowerCase() + "’s" + '"]');
        // for (let targetNode of targetNodes) {
        for (let i = 0; i < targetNodes.length; i++) {
            let targetNode = targetNodes[i];
            targetNode.textContent = replacement + "’s";
        }
    }

    static getScreenWidth() {
        let w = window,
            d = document,
            e = d.documentElement,
            g = d.getElementsByTagName('body')[0];

        return w.innerWidth || e.clientWidth || g.clientWidth;
    }

    /**
     * adopted from https://stackoverflow.com/a/901144/1251343
     * @param name
     * @param url
     * @returns {*}
     */
    static getUrlParameterByName(name, url) {
        if (!url) {
            url = window.location.href;
        }

        name = name.replace(/[\[\]]/g, "\\$&");

        let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
        let results = regex.exec(url);

        if (!results) {
            return null;
        }

        if (!results[2]) {
            return '';
        }

        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    /**
     * wraps saving
     * @param name
     * @param value
     */
    static setLocal(name, value) {
        if (typeof Storage !== 'undefined') {
            try {
                localStorage.setItem(name, value);
            }
            catch (e) {
                console.log(e);
            }
        }
    }


}

class LocalStorageWrapper {

    static isSupported() {
        return (typeof Storage !== 'undefined');
    }

    static set(name, value) {
        if (!LocalStorageWrapper.isSupported()) {
            return;
        }

        try {
            localStorage.setItem(name, value);
        }
        catch (e) {
            console.log('ERROR: ' + e.message);
        }
    }

    static get(item) {
        if (!LocalStorageWrapper.isSupported()) {
            return;
        }

        try {
            return localStorage.getItem(item);
        }
        catch (e) {
            console.log('ERROR: ' + e.message);
        }
    }

    static remove(item) {
        if (!LocalStorageWrapper.isSupported()) {
            return;
        }

        try {
            return localStorage.removeItem(item);
        }
        catch (e) {
            console.log('ERROR: ' + e.message);
        }
    }

    static clear() {
        if (!LocalStorageWrapper.isSupported()) {
            return;
        }

        try {
            return localStorage.clear();
        }
        catch (e) {
            return localStorage.clear();
        }
    }
}

class PageSettingsHolder {


    constructor(options) {
        if (options) {
            this.options = options;
        } else {
            this.options = {};
        }
    }

    has(name) {
        return (typeof this.options[name] !== 'undefined');
    }

    set(name, val) {
        this.options[name] = val;
        return this;
    }

    get(name) {
        if (this.options[name]) {
            return this.options[name];
        }

        return null;
    }
}

// ##################################################### START PLAY AUDIO ##############################################
/**
 * Manages playing of audio block contents
 * @param container - html tag with class playable-audio-wrapper and must have a img button
 * @param pcuesExtensionInterface
 * @constructor
 */
class TextPlayer {

    constructor(container, player) {
        this.container = container;
        this.player = player;

        this.ATTRIB_HIGHLIGHT_STRATEGY = 'data-highlight-color';
        this.ATTRIB_SPEED = 'data-speed';

        // @todo: for deletion
        this.ATTRIB_STOP_WORDS = 'data-stop-words';

        this.PLAY_MODE_FULL = 'full';
        this.PLAY_MODE_READ_WORDS = 'read_words';

        this.NORMAL_SPEED = 1.0;
        this.NORMAL_INTER_WORD_SPEED = 0.0;

        // magic ingredient - match only words that are not inside tags
        // @todo modify so partial words inside inline tags can be gathered
        // this.MAGIC_REG_EX = new RegExp("\\b([A-Za-z0-9\\u2018-\\u2019'\".%\\-])+\\b(?!([^<]+)?>)", "g");
        this.MAGIC_REG_EX = new RegExp(/\b([A-Za-z0-9\u2018-\u2019'".%\-])+\b(?!([^<]+)?>)/g);

        this.textContent = this.container.textContent.trim();

        this.highlighter = null;

        this.rateSpeed = null;
        this.interWordDelay = null;

        this.wordMonitor = new Map();
        this.playOptions = null;
        this.playHandle = null;

        this.lastChunk = false;

        this.playMode = this.PLAY_MODE_FULL;

        this.curExpectedReadWordIndex = 0;
        this.expectedReadWordIndices = [];
        this.readWordBlinker = null;

        // starting stop point
        this.curStopPointIndex = 0;
        this.stopPoints = [];

        this.initialize();
    }

    /**
     * resets variables to prepare for next play
     */
    reset() {
        // @todo consider this to be a part of endPlay method
        this.highlighter.removeHighlight();

        this.highlighter.dismissReadWordBlinker();
        // this.extractTextContent();

        // starting stop point
        this.curStopPointIndex = 0;
        this.extractSettings();

        this.playOptions.speechRate = parseFloat(this.rateSpeed);
        this.playOptions.interwordDelayMS = parseInt(this.interWordDelay);

        this.wordMonitor.clear();
        this.lastChunk = false;

        if (this.playOptions.startAtWord) {
            delete this.playOptions.startAtWord;
        }

        if (this.playOptions.endAtWord) {
            delete this.playOptions.endAtWord;
        }

        if (this.playOptions.stopWords) {
            delete this.playOptions.stopWords;
        }
    }

    /**
     * Perform initializations
     */
    initialize() {
        // @todo validate container node
        this.validate();

        this.prepareBlock();
        this.extractSettings();

        this.preparePlayOptions();
    }

    /**
     * extracts the values stored in the the container node
     */
    extractSettings() {
        if (this.container.hasAttribute(this.ATTRIB_HIGHLIGHT_STRATEGY)) {
            this.createHighlighter(this.container.getAttribute(this.ATTRIB_HIGHLIGHT_STRATEGY), this.container);
        }


        this.extractSpeed();
        this.extractPlayMode();
        this.extractTextContent();
    }

    createHighlighter(strategy, container) {
        switch (strategy) {
            case 'pcued':
                this.highlighter = new PcuedHighlighter(container);
                break;

            case 'pcued-overlay':
                this.highlighter = new PcuedOverlayHighlighter(container);
                break;

            case 'orange':
                this.highlighter = new ColoredHighlighter(container, 'highlight_orange', {
                    color: '#000000',
                    backColor: '#fe7411'
                });
                break;

            case 'green':
                this.highlighter = new ColoredHighlighter(container, 'highlight_green', {
                    color: '#000000',
                    backColor: '#78d802'
                });
                break;

            case 'blue':
                this.highlighter = new ColoredHighlighter(container, 'highlight_blue', {
                    color: '#000000',
                    backColor: '#46bbe6'
                });
                break;

            case 'black':
                this.highlighter = new ColoredHighlighter(container, 'highlight_black', {
                    color: '#ffffff',
                    backColor: '#000000'
                });
                break;

            default:
                this.highlighter = new ColoredHighlighter(container, 'highlight', {});
                break;
        }
    }

    /**
     * get the audio speed
     */
    extractSpeed() {
        if (this.container.getAttribute(this.ATTRIB_SPEED)) {
            let params = this.container.getAttribute(this.ATTRIB_SPEED).split(',');
            this.rateSpeed = params[0];
            this.interWordDelay = params[1];

            // [this.rateSpeed, this.interWordDelay] = this.container.getAttribute(this.ATTRIB_SPEED).split(',');

            this.rateSpeed = parseFloat(this.rateSpeed);
            this.interWordDelay = parseInt(this.interWordDelay);
        }
    }

    /**
     * get the text content of the block
     */
    extractTextContent() {
        this.textContent = this.container.textContent.trim();
    }

    /**
     * determines the play mode
     * and gets stop points if in read word mode
     */
    extractPlayMode() {
        this.stopPoints = [];
        let readWords = this.container.getElementsByClassName('read-word');

        if (readWords.length > 0) {
            for (let i = 0; i < readWords.length; i++) {
                let readWord = readWords[i];

                let word = readWord.querySelector('.word');
                if (word) {
                    this.stopPoints.push(parseInt(word.getAttribute('data-word-index')));
                }
                else {
                    // word could be an outside wrapper

                    word = readWord.parentNode;
                    while (!word.classList.contains('word') && word !== this.container) {
                        word = word.parentNode;
                    }

                    if (word.classList.contains('word')) {
                        this.stopPoints.push(parseInt(word.getAttribute('data-word-index')));
                    }
                }
            }
            this.playMode = this.PLAY_MODE_READ_WORDS;
        }
        else {
            this.playMode = this.PLAY_MODE_FULL;
        }

        // if (this.container.getAttribute(this.ATTRIB_STOP_WORDS)) {
        //     this.stopPoints = this.container.getAttribute(this.ATTRIB_STOP_WORDS).trim().split(',');
        //     if (this.stopPoints.length > 0) {
        //         this.stopPoints = this.stopPoints.map(point => parseInt(point));
        //         this.playMode = this.PLAY_MODE_READ_WORDS;
        //     }
        // } else {
        //     this.playMode = this.PLAY_MODE_FULL;
        // }
    }

    prepareBlock() {
        let self = this;

        // transform content
        if (this.container.getAttribute('block-prepared') === 'true') {
            return;
        }

        // monitors instance number of word
        let wordMonitor = new Map();

        // keeps track of the word index
        let wordIndexCtr = 1;

        // ##############################  HEART STARTS HERE  ##############################
        let regEx = new RegExp(/[A-Za-z0-9\u2018-\u2019'"%\-]+(?!([^<]+)?>)/g);
        let docFrag = document.createDocumentFragment();

        /**
         * finds all the words in the node and set metadata in preparation for audio block playing
         * @param node
         * @returns {number}
         */
        let traverse = function(node) {
            if (node.nodeType === Node.TEXT_NODE) {
                // extract words

                let tempNode = document.createElement('span');
                tempNode.innerHTML = node.textContent.replace(regEx, function(match, p1, p2) {
                    // preserve spaces
                    if (match === 'nbsp') {
                        return match;
                    }

                    if (wordMonitor.has(match)) {
                        wordMonitor.set(match, wordMonitor.get(match) + 1);
                    } else {
                        wordMonitor.set(match, 1);
                    }

                    let span = document.createElement('span');

                    span.classList.add('word');
                    span.setAttribute('data-word', match);
                    span.setAttribute('data-word-orig', match.toLowerCase()); // used for word replacements
                    span.setAttribute('data-word-instance', wordMonitor.get(match));
                    span.setAttribute('data-word-index', wordIndexCtr.toString());
                    span.textContent = match;

                    wordIndexCtr++;

                    return span.outerHTML;
                });

                let countAddedNotes = tempNode.childNodes.length;
                while (tempNode.hasChildNodes()) {
                    docFrag.appendChild(tempNode.firstChild);
                }

                node.parentNode.replaceChild(docFrag, node);

                // This is used to jump the traversal of nodes since new nodes added are live and should not
                // be reprocessed
                return countAddedNotes - 1;
            }
            else if (node.classList.contains('word')) {
                let match = node.textContent;

                if (wordMonitor.has(match)) {
                    wordMonitor.set(match, wordMonitor.get(match) + 1);
                } else {
                    wordMonitor.set(match, 1);
                }

                node.setAttribute('data-word', match);
                node.setAttribute('data-word-orig', match.toLowerCase()); // used for word replacements
                node.setAttribute('data-word-instance', wordMonitor.get(match));
                node.setAttribute('data-word-index', wordIndexCtr.toString());

                wordIndexCtr++;
            }
            else if (node.hasChildNodes()) {
                for (let i = 0; i < node.childNodes.length; i++) {
                    i += traverse(node.childNodes[i]);
                }
            }

            return 0;
        };

        let combineWordNodes = function(wordA, wordB) {
            let newWord = wordA.textContent + wordB.textContent;

            let adjustWordIndices = function(wordNode) {
                let sameWords = self.querySelectorAll('span[data-word="' + wordNode.textContent + '"]');
                for (let i = 0; i < sameWords.length; i++) {
                    let compWord = sameWords[i];

                    if (parseInt(compWord.getAttribute('data-word-instance')) > parseInt(wordNode.getAttribute('data-word-instance'))) {
                        compWord.setAttribute('data-word-instance', parseInt(compWord.getAttribute('data-word-instance')) - 1);
                    }
                }
            };

            adjustWordIndices(wordA);
            adjustWordIndices(wordB);

            // find common parent and wrap word from there
            let wordAParent = wordA.parentNode;
            let wordBParent = wordB.parentNode;



            while (wordAParent !== wordBParent) {
                if (wordBParent.parentNode === self.container) {
                    break;
                }

                wordBParent = wordBParent.parentNode;
            }


        };

        let openWord = null;
        let mergeWords = function(node) {
            if (node.nodeName.toLowerCase() === 'span' && node.classList.contains('word')) {
                if (openWord !== null) {
                    combineWordNodes(openWord, node);
                }

                if (node === node.parentNode.lastChild) {
                    openWord = node;
                }
            }
            else if (node.hasChildNodes()) {
                for (let i = 0; i < node.childNodes.length; i++) {
                    mergeWords(node.childNodes[i]);
                }
            } else {
                openWord = null;
            }
        };

        traverse(this.container);

        // @todo combine words

        // ##############################   HEART ENDS HERE  ###############################

        // @todo: find a way to make this transfer this behaviior to the highlighter
        if (this.container.getAttribute(this.ATTRIB_HIGHLIGHT_STRATEGY) === 'pcued') {
            // adjust the widths
            let wordNodes = this.container.getElementsByClassName('word');
            // for (let node of wordNodes) {
            for (let i = 0; i < wordNodes.length; i++) {
                let node = wordNodes[i];

                node.style.width = (node.offsetWidth * 1.2) + 'px';
                node.style.display = 'inline-block';
            }
        }

        this.container.setAttribute('block-prepared', true);
    }

    /**
     * unused
     * Supposed to restore original state of content before it was the block was prepared for processing
     */
    restoreHtml() {
        // restore document to original form
        let wordNodes = document.getElementsByClassName('word');
        // for (let node of wordNodes) {
        for (let i = 0; i < wordNodes.length; i++) {
            let node = wordNodes[i];
            this.container.replaceChild(document.createTextNode(node.getAttribute('data-word')), node);
        }

        this.container.removeAttribute('block-prepared');
    }

    /**
     * setup the options that will be sent to play text
     */
    preparePlayOptions() {
        let container = this.container;
        let wordMonitor = this.wordMonitor;

        let self = this;

        this.playOptions = {
            onStarted: function(e) {
                console.log('STARTING!');
                self.player.locked = false;
            },

            onWordStart: function(e) {
                if (e.type !== 'word') {
                    return;
                }

                let word = e.value;

                self.updateWordMonitor(word);

                console.log('Received word: ' + word);

                // remove previous highlights before highlighting next word
                self.highlighter.removeHighlight();

                // target word
                let targetNode = container.querySelector('[data-word="' + word + '"][data-word-instance="' + wordMonitor.get(word) + '"]');

                if (targetNode) {
                    console.log('Found searched word: ' + word);

                    self.highlighter.highlight(targetNode, word, e.pcued);
                    DemoModuleUtils.scrollToWord(targetNode);
                }
            },
            onEnded: function(result) {
                if (!result.completed) {
                    console.log('Play failed!');
                    return self.endPlay();
                }

                console.log('Play ended.');

                self.highlighter.removeHighlight();

                if (self.playMode === self.PLAY_MODE_READ_WORDS) {
                    if (!self.lastChunk) {
                        self.nextReadWord();
                    } else {
                        return self.endPlay();
                    }

                }
                else if (self.playMode === self.PLAY_MODE_FULL) {
                    self.endPlay();
                }
                // self.restoreHtml();

                delete self.playOptions.startAtWord;
                delete self.playOptions.endAtWord;
            }
        };

        if (null !== this.rateSpeed) {
            this.playOptions.speechRate = parseFloat(this.rateSpeed);
            this.playOptions.interwordDelayMS = parseInt(this.interWordDelay);
        }
    }

    nextReadWord() {
        this.showReadWord(this.curExpectedReadWordIndex);
        this.curExpectedReadWordIndex++;
    }

    /**
     * displays the read word
     * @param index - position of the word in the block
     */
    showReadWord(index) {
        // get the target node
        let targetNode = this.container.querySelector('[data-word-index="' + this.expectedReadWordIndices[index] + '"]');
        let self = this;
        let blinkInterval = 1000;

        this.highlighter.blinkReadWord(targetNode, blinkInterval, function() {
            self.player.showPcuedPopup(targetNode);
        });
    }

    /**
     * Validates the passed audio block to see if it meets all the requirements
     */
    validate() {
        // @todo: implement
    }

    /**
     * @todo: determine what stuff can be combined in reset
     */
    endPlay() {
        console.log('End play invoked.');

        if (this.playHandle) {
            this.playHandle.stop();
            // this.playHandle.release();

            this.highlighter.removeHighlight();
            this.highlighter.dismissReadWordBlinker();

            this.playHandle = null;
            this.lastChunk = false;
        }

        // Make sure player is unlocked
        if (this.player.locked) {
            this.player.locked = false;
        }
    }

    /**
     * restart play
     */
    playFromStart(options) {
        this.reset();
        this.play(options);
    }

    /**
     * Plays the audio block normally without regard of the read words.
     * The reading speed is set to normal
     */
    playNormal(options) {
        this.reset();

        this.playMode = this.PLAY_MODE_FULL;

        this.playOptions.speechRate = this.NORMAL_SPEED;
        this.playOptions.interwordDelayMS = this.NORMAL_INTER_WORD_SPEED;

        if (options.highlightStrategy) {
            this.createHighlighter(options.highlightStrategy, this.container);
        } else {
            this.highlighter = new PcuedOverlayHighlighter(this.container);
        }

        console.log('Requesting to play: ' + this.textContent);
        this.prepareBlock();

        this.doPlay(this.textContent, options);
    }

    /**
     * actual play
     * @param text
     * @param options
     */
    doPlay(text, options) {
        if (this.player.locked) {
            console.log('Player locked!');
            return;
        }

        // text = text.replace(/[&<>]+/g, '');

        let tempOptions = this.playOptions;

        if (options.rateSpeed && !(options.rateSpeed === 1.0 && options.interWordDelay === 0)) {
            console.log('Using global speed!');
            tempOptions.speechRate = options.rateSpeed;
            tempOptions.interwordDelayMS = options.interWordDelay;
        }

        if (this.player.playText) {
            this.player.locked = true;
            this.playHandle = this.player.playText(text, tempOptions);
        }
    }

    /**
     * plays all the text in the block
     */
    play(options) {
        if (this.player.locked) {
            console.log('Player locked!');
            return;
        }

        if (this.playMode === this.PLAY_MODE_FULL) {
            console.log('Requesting Polly to play: ' + this.textContent);
            this.prepareBlock();

            // this.playHandle = this.player.playText(this.textContent, this.playOptions);
            this.doPlay(this.textContent, options);
        }
        else if (this.playMode === this.PLAY_MODE_READ_WORDS) {
            console.log('CUR STOP POINT INDEX = ' + this.curStopPointIndex);
            console.log('STOP POINTS = ' + this.stopPoints);

            let lastWordIndex = this.textContent.match(this.MAGIC_REG_EX).length;

            let startWordIndex = null;
            let endWordIndex = null;

            if (this.curStopPointIndex === 0) {
                // we're playing frm the start
                this.prepareBlock();
                startWordIndex = 0;
                endWordIndex = this.stopPoints[this.curStopPointIndex] - 1;
            }
            else {
                // walk previous read words
                for (let i = 0; i < this.expectedReadWordIndices.length; i++) {
                    // get the word from the index
                    let index = this.expectedReadWordIndices[i];
                    let word =  this.container.querySelector('[data-word-index="' + index + '"]').textContent;
                    console.log('Walked the word: ' + word);
                    this.updateWordMonitor(word);

                    if (index >= lastWordIndex) {
                        return this.endPlay();
                    }
                }

                // compute next set of words to be played
                startWordIndex = parseInt(this.stopPoints[this.curStopPointIndex - 1]) + 1;
                if (startWordIndex > lastWordIndex) {
                    // nothing to play, need to reset
                    return this.endPlay();
                }

                while (this.stopPoints.includes(startWordIndex)) {
                    this.curStopPointIndex++;
                    startWordIndex = parseInt(this.stopPoints[this.curStopPointIndex - 1]) + 1;
                }

                if (this.curStopPointIndex < this.stopPoints.length) {
                    endWordIndex = this.stopPoints[this.curStopPointIndex] - 1;
                } else {
                    endWordIndex = null;
                }

                startWordIndex--;

                if (endWordIndex && endWordIndex >= lastWordIndex) {
                    endWordIndex = null;
                }
            }

            // gather read words and update word monitor
            if (endWordIndex !== null) {
                let walkStart = endWordIndex + 1;
                let walkEnd = null;

                for (let i = walkStart; this.stopPoints.includes(i); i++) {
                    walkEnd = i;
                }

                this.curExpectedReadWordIndex = 0;
                this.expectedReadWordIndices = [];
                for (let i = walkStart; i <= walkEnd; i++) {
                    this.expectedReadWordIndices.push(i);
                }
            }

            console.log('INDEX RANGE ' + startWordIndex + ' - ' + endWordIndex);

            this.playOptions.startAtWord = startWordIndex;

            if (null !== endWordIndex) {
                this.playOptions.endAtWord = endWordIndex;
            } else {
                this.lastChunk = true;
            }

            if (!(startWordIndex === 0 && endWordIndex === 0)) {
                if (endWordIndex !== null) {
                    // send stop word positions
                    let stopWordIndices = [];
                    for (let i = 0; i < this.stopPoints.length; i++) {
                        stopWordIndices.push(this.stopPoints[i] - 1);
                    }

                    this.playOptions.stopWords = stopWordIndices;
                }

                let self = this;
                setTimeout(function() {
                    self.doPlay(self.textContent, options);
                    // self.playHandle = self.player.playText(self.textContent, self.playOptions);
                }, 250);
            } else {
                this.nextReadWord();
            }

            if (this.curStopPointIndex < this.stopPoints.length) {
                this.curStopPointIndex++;
            } else {
                // go back to start of the cycle
                this.curStopPointIndex = 0;
            }
        }
    }

    updateWordMonitor(word) {
        if (typeof word !== 'string') {
            return;
        }

        if (this.wordMonitor.has(word)) {
            this.wordMonitor.set(word, this.wordMonitor.get(word) + 1);
        } else {
            this.wordMonitor.set(word, 1);
        }
    }

    replaceWord(target, replacement) {
        this.prepareBlock();

        let targetNodes = this.container.querySelectorAll('[data-word-orig="' + target.toLowerCase() + '"]');
        // for (let targetNode of targetNodes) {
        for (let i = 0; i < targetNodes.length; i++) {
            let targetNode = targetNodes[i];

            targetNode.setAttribute('data-word', replacement);
            targetNode.textContent = replacement;
        }

        // @todo find better way to handle apostrophe s
        targetNodes = this.container.querySelectorAll('[data-word-orig="' + target.toLowerCase() + "'s" + '"]');
        // for (let targetNode of targetNodes) {
        for (let i = 0; i < targetNodes.length; i++) {
            let targetNode = targetNodes[i];

            if (replacement.slice(-1).toLowerCase() === 's') {
                targetNode.setAttribute('data-word', replacement + "'");
                targetNode.textContent = replacement + "'";
            } else {
                targetNode.setAttribute('data-word', replacement + "'s");
                targetNode.textContent = replacement + "'s";
            }
        }

        targetNodes = this.container.querySelectorAll('[data-word-orig="' + target.toLowerCase() + "’s" + '"]');
        // for (let targetNode of targetNodes) {
        for (let i = 0; i < targetNodes.length; i++) {
            let targetNode = targetNodes[i];

            if (replacement.slice(-1).toLowerCase() === 's') {
                targetNode.setAttribute('data-word', replacement + "’");
                targetNode.textContent = replacement + "’";
            } else {
                targetNode.setAttribute('data-word', replacement + "’s");
                targetNode.textContent = replacement + "’s";
            }
        }

        this.extractTextContent();
    }
}

/**
 * used to highlight play audio block words
 */
class Highlighter {

    constructor(container) {
        this.container = container;
        this.readWordBlinker = null;
    }

}

class ColoredHighlighter extends Highlighter {

    constructor(container, className, options) {
        super(container);
        this.className = className;

        this.color = null;
        if (options.color) {
            this.color = options.color;
        }

        this.backColor = null;
        if (options.backColor) {
            this.backColor = options.backColor;
        }

        this.blinkClass = null;
        if (options.blinkClass) {
            this.blinkClass = options.blinkClass;
        } else {
            this.blinkClass = this.className;
        }

        this.blinkerTargetNode = null;
        this.blinkerOrigColor = null;
        this.blinkerOrigBackColor = null;
    }

    doHighlight(node) {
        if (node.nodeType === Node.TEXT_NODE) {
            return;
        }

        node.classList.add(this.className);

        if (node.hasChildNodes()) {
            for (let i = 0; i < node.childNodes.length; i++) {
                this.doHighlight(node.childNodes[i]);
            }
        }
    }

    highlight(targetNode, word, pcuedHtml) {
        // targetNode.classList.add(this.className);
        this.doHighlight(targetNode);
    }

    doRemoveHighlight(node) {
        if (node.nodeType === Node.TEXT_NODE) {
            return;
        }

        node.classList.remove(this.className);

        if (node.hasChildNodes()) {
            for (let i = 0; i < node.childNodes.length; i++) {
                this.doRemoveHighlight(node.childNodes[i]);
            }
        }
    }

    removeHighlight() {
        // remove previous highlight
        let wordNodes = this.container.getElementsByClassName(this.className);

        // for (let node of wordNodes) {
        for (let i = 0; i < wordNodes.length; i++) {
            // wordNodes[i].classList.remove(this.className);
            this.doRemoveHighlight(wordNodes[i]);
        }
    }

    dismissReadWordBlinker() {
        if (!this.readWordBlinker) {
            return;
        }

        clearInterval(this.readWordBlinker);
        this.readWordBlinker = null;
        // this.blinkerTargetNode.style.color = this.blinkerOrigColor;
        // this.blinkerTargetNode.style.backgroundColor = this.blinkerOrigBackColor;
        this.blinkShow(this.blinkerTargetNode);
    }

    blinkHide(node) {
        if (node.nodeType === Node.TEXT_NODE) {
            return;
        }

        node.classList.add(this.blinkClass);

        if (node.hasChildNodes()) {
            for (let i = 0; i < node.childNodes.length; i++) {
                this.blinkHide(node.childNodes[i]);
            }
        }
    }

    blinkShow(node) {
        if (node.nodeType === Node.TEXT_NODE) {
            return;
        }

        node.classList.remove(this.blinkClass);

        if (node.hasChildNodes()) {
            for (let i = 0; i < node.childNodes.length; i++) {
                this.blinkShow(node.childNodes[i]);
            }
        }
    }

    blinkReadWord(targetNode, interval, onFinish) {
        this.blinkerTargetNode = targetNode;
        this.blinkerOrigColor = targetNode.style.color;
        this.blinkerOrigBackColor = targetNode.style.backgroundColor;

        let self = this;
        let change = true;

        // redundant check

        if (this.readWordBlinker) {
            clearInterval(this.readWordBlinker);
            this.readWordBlinker = null;
        }

        this.readWordBlinker = setInterval(function() {
            if (change) {
                self.blinkHide(targetNode);
                // targetNode.style.backgroundColor = self.color;
                // targetNode.style.color = self.backColor;
            } else {
                self.blinkShow(targetNode);
                // targetNode.style.backgroundColor = self.backColor;
                // targetNode.style.color = self.color;
            }

            change = !change;
        }, interval);

        let handleNodeClick = function(e) {
            e.stopPropagation();
            self.dismissReadWordBlinker();

            targetNode.removeEventListener('click', handleNodeClick);

            // call callback function
            onFinish();

            return false;
        };

        targetNode.addEventListener('click', handleNodeClick);
    }
}

class PcuedHighlighter extends Highlighter {

    constructor(container) {
        super(container);

        this.blinkerTargetNode = null;
        this.blinkerOrigColor = null;
        this.blinkerOrigBackColor = null;

        this.origWordFormat = null;
    }

    highlight(targetNode, word, pcuedHtml) {
        // highlight by changing text with pcued form
        targetNode.style.position = 'relative';

        // clear all children node
        this.origWordFormat = targetNode.innerHTML;
        targetNode.innerHTML = '';
        // while (targetNode.hasChildNodes()) {
        //     targetNode.removeChild(targetNode.lastChild);
        // }

        let invisibleSpan = document.createElement('span');
        invisibleSpan.style.visibility = 'hidden';
        invisibleSpan.style.whiteSpace = 'nowrap';
        invisibleSpan.textContent = word;
        targetNode.appendChild(invisibleSpan);

        let innerSpan = document.createElement('span');
        innerSpan.style.display = 'inline-flex';
        innerSpan.style.position = 'absolute';
        innerSpan.style.left = '0';
        innerSpan.style.top = '0';

        if (pcuedHtml) {
            innerSpan.innerHTML = pcuedHtml;

            // needed so that pcues rendering of raised letters does not lower baseline
            if (innerSpan.hasChildNodes() && innerSpan.firstChild.nodeType === Node.ELEMENT_NODE) {
                innerSpan.firstChild.classList.add('pcued-container-content');
            }
        } else {
            innerSpan.innerHTML = targetNode.textContent;
        }

        targetNode.appendChild(innerSpan);
        targetNode.classList.add('pcued-processed');
    }

    removeHighlight() {
        // remove previous highlight
        let wordNodes = this.container.getElementsByClassName('pcued-processed');
        // for (let wordNode of wordNodes) {
        for (let i= 0; i < wordNodes.length; i++) {
            let wordNode = wordNodes[i];

            wordNode.classList.remove('pcued-processed');

            if (this.origWordFormat !== null) {
                wordNode.innerHTML = this.origWordFormat;
            } else {
                wordNode.textContent = wordNode.getAttribute('data-word');
            }

        }
    }

    dismissReadWordBlinker() {
        if (!this.readWordBlinker) {
            return;
        }

        clearInterval(this.readWordBlinker);

        this.blinkerTargetNode.style.color = this.blinkerOrigColor;
        this.blinkerTargetNode.style.backgroundColor = this.blinkerOrigBackColor;
    }

    blinkReadWord(targetNode, interval, onFinish) {
        this.blinkerTargetNode = targetNode;
        this.blinkerOrigColor = targetNode.style.color;
        this.blinkerOrigBackColor = targetNode.style.backgroundColor;

        let self = this;
        let change = true;
        this.readWordBlinker = setInterval(function() {
            if (change) {
                targetNode.style.backgroundColor = '#000000';
                targetNode.style.color = '#ffffff';
            } else {
                targetNode.style.backgroundColor = '#ffffff';
                targetNode.style.color = '#000000';
            }

            change = !change;
        }, interval);

        let handleNodeClick = function(e) {
            e.stopPropagation();
            self.dismissReadWordBlinker();

            targetNode.removeEventListener('click', handleNodeClick);

            onFinish();

            return false;
        };

        targetNode.addEventListener('click', handleNodeClick);
    }
}

class PcuedOverlayHighlighter extends Highlighter {

    constructor(container) {
        super(container);
    }

    createLayeredPopup(targetNode, pcuedHtml, autoScroll) {
        // these are relative to the viewport, i.e. the window
        let clientRect = targetNode.getBoundingClientRect();
        let centerX = clientRect.left + clientRect.width / 2;
        let centerY = clientRect.top + clientRect.height / 2;

        let span = document.createElement('span');

        span.style.padding = '5px';
        span.style.border = '2px solid blue';
        span.style.color = "#000000";
        span.style.backgroundColor = '#ffffff';

        // @todo: get the computed style if allowed
        span.style.font = targetNode.style.font;

        // compute font style
        let style = window.getComputedStyle(targetNode, null).getPropertyValue('font-size');
        let fontSize = parseFloat(style);
        // now you have a proper float for the font size (yes, it can be a float, not just an integer)
        span.style.fontSize = (fontSize + 1) + 'px';

        span.style.borderRadius = '5px';
        span.style.zIndex = '9999';

        if (pcuedHtml) {
            span.innerHTML = pcuedHtml;

            // needed so that pcues rendering of raised letters does not lower baseline
            if (span.hasChildNodes() && span.firstChild.nodeType === Node.ELEMENT_NODE) {
                span.firstChild.classList.add('pcued-container-content');
            }

        } else {
            span.innerHTML = targetNode.textContent;
        }

        span.style.position = 'absolute';

        span.classList.add('pcued-overlay-popup');
        span.style.visibility = 'hidden';

        // attach to the document
        // document.body.appendChild(span);
        this.container.appendChild(span);

        let left = centerX - (span.offsetWidth / 2) + window.scrollX;
        span.style.left = left + 'px';

        // display popup on top of the word taking into consideration the scrolling
        // span.style.top = centerY - (span.offsetHeight * 1.1) + window.scrollY + 'px';

        // display popup in the center of the word covering it
        // scrolling is taken into consideration
        let top = centerY - (span.offsetHeight / 2) + window.scrollY;

        if (autoScroll) {
            DemoModuleUtils.scrollToWord(targetNode);
        }

        span.style.top = top + 'px';
        span.style.visibility = 'visible';

        return span;
    }

    highlight(targetNode, word, pcuedHtml) {
        this.createLayeredPopup(targetNode, pcuedHtml, true);
    }

    removeHighlight() {
        let wordPopups = this.container.getElementsByClassName('pcued-overlay-popup');
        // for (let popup of wordPopups) {
        for (let i = 0; i < wordPopups.length; i++) {
            this.container.removeChild(wordPopups[i]);
        }
    }

    dismissReadWordBlinker() {
        if (this.readWordBlinker) {
            clearInterval(this.readWordBlinker);
        }
    }

    blinkReadWord(targetNode, interval, onFinish) {
        let popup = this.createLayeredPopup(targetNode);

        // blink the popup
        let self = this;
        let change = true;
        this.readWordBlinker = setInterval(function() {
            if (change) {
                popup.style.backgroundColor = '#000000';
                popup.style.color = '#ffffff';
                popup.style.borderColor = 'green';
            } else {
                popup.style.backgroundColor = '#ffffff';
                popup.style.color = '#000000';
                popup.style.borderColor = 'blue';
            }

            change = !change;
        }, interval);

        let handleNodeClick = function(e) {
            console.log('Stopping read word blinker.');
            e.stopPropagation();
            self.dismissReadWordBlinker();

            targetNode.removeEventListener('click', handleNodeClick);
            self.container.removeChild(popup);

            onFinish();
            return false;
        };

        popup.addEventListener('click', handleNodeClick);
    }
}

class PlayAudioManager {

    constructor() {
        this.audioBlocks = new Map();
        this.curPlayBlock = null;
        this.player = null;
        this.lastBlockId = 1;

        this.initialize();
    }

    replaceTextInAllBlocks(target, replacement) {
        this.audioBlocks.forEach(function(block) {
            block.replaceWord(target, replacement);
        });
    }

    initialize() {
        this.bindEvents();
    }

    /**
     * registers a single play block at a later time
     * @param elem
     */
    addBlock(elem) {
        let uniqueName = 'play_block_' + this.lastBlockId;
        this.lastBlockId++;
        elem.setAttribute('data-block-id', uniqueName);

        let block = new TextPlayer(elem, this.player);

        // pre-format audio blocks
        block.prepareBlock();

        this.audioBlocks.set(uniqueName, block);
    }

    registerRegion(elem) {
        // gather all play audio blocks and attach unique identifier
        console.log('Configuring additional blocks...');

        let self = this;

        let audioNodes = elem.querySelectorAll('.playable-audio-wrapper');
        Array.from(audioNodes).forEach(function(node) {
           self.addBlock(node);
        });
    }

    identifyBlocks() {
        // gather all play audio blocks and attach unique identifier
        console.log('Configuring blocks...');

        let audioNodes = document.getElementsByClassName('playable-audio-wrapper');
        let i;
        for (i = 0; i < audioNodes.length; i++) {
            let uniqueName = 'play_block_' + (i + 1);

            audioNodes[i].setAttribute('data-block-id', uniqueName);

            let block = new TextPlayer(audioNodes[i], this.player);

            // pre-format audio blocks
            block.prepareBlock();

            this.audioBlocks.set(uniqueName, block);
        }

        this.lastBlockId = i + 1;

        // if (LocalStorageWrapper.get('autoApplyLastReplacementWordState') === 'true'
        //     && !settingsManager.hasRanAutoApplyLastReplacementWord) {
        //     console.log('Running auto replace...');
        //     settingsManager.runAutoWordReplacement();
        // }
    }

    displayPlayerNotFound() {
        console.log('ERROR: pcuesExtensionInterface not found!');
    }

    initiatePlayer() {
        if (typeof pcuesExtensionInterface !== 'undefined') {
            this.player = pcuesExtensionInterface;
            this.player.setOption('manualPopupUserDismissible', false);
        }

        if (!this.player) {
            this.displayPlayerNotFound();
            return;
        }

        this.identifyBlocks();
    }

    bindEvents() {

        let self = this;

        if (typeof window !== 'undefined') {
            /**
             * operations that needs to be performed AFTER document has loaded
             */
            window.addEventListener('DOMContentLoaded', function() {

                document.body.addEventListener('pcuesPopupClosed', function (event) {
                    console.log('POPUP CLOSED!');

                    let targetNode = event.detail.target;
                    let container = targetNode.parentNode;
                    while (container && !container.classList.contains('playable-audio-wrapper')) {
                        container = container.parentNode;
                        if (!container) {
                            break;
                        }
                    }

                    if (container && container.classList.contains('playable-audio-wrapper')) {
                        // keep track of currently playing block
                        self.curPlayBlock = self.audioBlocks.get(container.getAttribute('data-block-id'));
                    }

                    let playBlock = self.curPlayBlock;

                    // check if all read words have been presented
                    if (playBlock.curExpectedReadWordIndex < playBlock.expectedReadWordIndices.length) {
                        playBlock.nextReadWord();
                    } else {
                        let options = {};

                        if (LocalStorageWrapper.get('useGlobalSpeed') === 'true') {
                            options.rateSpeed = parseFloat(LocalStorageWrapper.get('speechRate'));
                            options.interWordDelay = parseFloat(LocalStorageWrapper.get('interwordDelayMS'));
                        }

                        playBlock.play(options);
                    }
                });

                document.body.addEventListener('onPcuesExtensionLoaded', function () {
                    self.initiatePlayer();
                });

                // playable text
                document.body.addEventListener('click', function (event) {
                    if (event.target.classList.contains('playable-audio')) {
                        let audioButton = event.target;

                        console.log('Request to play received.');
                        if (self.player) {
                            let buttonWrapper = audioButton.parentNode;
                            while (buttonWrapper && !buttonWrapper.classList.contains('playable-audio-wrapper')) {
                                buttonWrapper = buttonWrapper.parentNode;
                                if (!buttonWrapper) {
                                    break;
                                }
                            }

                            if (buttonWrapper && buttonWrapper.classList.contains('playable-audio-wrapper')) {
                                if (self.curPlayBlock) {
                                    if (self.curPlayBlock.player.locked) {
                                        return;
                                    }

                                    if (self.curPlayBlock.playHandle) {
                                        self.curPlayBlock.endPlay();
                                    }
                                }

                                // keep track of currently playing block
                                self.curPlayBlock = self.audioBlocks.get(buttonWrapper.getAttribute('data-block-id'));

                                let options = {};

                                if (LocalStorageWrapper.get('useGlobalSpeed') === 'true') {
                                    options.rateSpeed = parseFloat(LocalStorageWrapper.get('speechRate'));
                                    options.interWordDelay = parseFloat(LocalStorageWrapper.get('interwordDelayMS'));
                                }

                                if (audioButton.getAttribute('data-button-type') === 'normal') {
                                    if (LocalStorageWrapper.get('useGlobalSpeed') === 'true' &&
                                        audioButton.hasAttribute('data-speed'))
                                    {
                                        let params = audioButton.getAttribute('data-speed').split(',');
                                        let rateSpeed = params[0];
                                        let interWordDelay = params[1];

                                        // let [rateSpeed, interWordDelay] = audioButton.getAttribute('data-speed').split(',');

                                        options.rateSpeed = parseFloat(rateSpeed);
                                        options.interWordDelay = parseInt(interWordDelay);
                                    }

                                    if (audioButton.hasAttribute('data-highlight-color')) {
                                        options.highlightStrategy = audioButton.getAttribute('data-highlight-color');
                                    }

                                    self.curPlayBlock.playNormal(options);

                                } else {
                                    self.curPlayBlock.playFromStart(options);
                                }
                            }
                        }
                        else {
                            self.initiatePlayer();
                        }
                    }
                });
            });
        }
    }

}

// if (typeof window !== 'undefined') {
//     window.addEventListener('DOMContentLoaded', function() {
        /** @var PlayAudioManager - manages everything about the play audio plugin **/
        let playAudioManager = new PlayAudioManager();
    // });
// }
// ####################################################### END PLAY AUDIO ##############################################

// ######################################################## MATH QUESTION ##############################################
if (typeof window !== 'undefined') {
    /**
     * operations that needs to be performed AFTER document has loaded
     */
    window.addEventListener('DOMContentLoaded', function() {

        let delaySettings = 2000; // 2 seconds
        let defaultCorrectMessage = 'Yes! Great Answer!';
        let defaultIncorrectMessage = 'Sorry!Try again!';

        const checkImage = '/wp-content/uploads/2018/03/transparent-check.png';
        const xImage = '/wp-content/uploads/2018/03/transparent-x.png';

        // math text input
        let answerButtons = document.getElementsByClassName('math-answer');
        let answerButtonClickHandler = function() {
            let wrapper = this.parentElement;
            while (wrapper && !wrapper.classList.contains('math-answer-wrapper')) {
                wrapper = wrapper.parentElement;
            }

            if (!wrapper) {
                console.log('ERROR: Math Question incorrectly configured.');
                return;
            }

            let inputControls = wrapper.getElementsByTagName('input');
            let textInput;

            for (let j = 0; j < inputControls.length; j++) {
                textInput = inputControls[j];

                if (textInput.tagName.toLowerCase() === 'input' && textInput.getAttribute('type') === 'text' &&
                    textInput.classList.contains('answer-input'))
                {
                    break;
                }
            }

            let message = '';
            if (textInput && this.getAttribute('data-correct-answer')) {
                let imgResult = null;
                if (textInput.value === this.getAttribute('data-correct-answer')) {
                    if (this.getAttribute('data-correct-message')) {
                        message = this.getAttribute('data-correct-message');
                    } else {
                        message = defaultCorrectMessage;
                    }

                    imgResult = checkImage;
                }
                else {
                    if (this.getAttribute('data-incorrect-message')) {
                        message = this.getAttribute('data-incorrect-message');
                    } else {
                        message = defaultIncorrectMessage;
                    }

                    imgResult = xImage;
                }

                let iconResult = document.createElement('img');
                iconResult.setAttribute('src', imgResult);
                iconResult.style.width = '1em';

                this.parentNode.insertBefore(iconResult, this.nextSibling);

                if (typeof pcuesExtensionInterface !== 'undefined') {
                    pcuesExtensionInterface.playText(message);
                }

                setTimeout(function() {
                    iconResult.remove();
                }, delaySettings);
            }
        };

        for (let i = 0; i < answerButtons.length; i++) {
            answerButtons[i].addEventListener('click', answerButtonClickHandler);
        }

        // multiple choice item
        let choices = document.getElementsByClassName('choice-item');
        let choiceClickHandler = function(event) {
            const button = event.target;
            let isCorrect = button.classList.contains('correct');
            let message = '';
            let imgResult;

            if (isCorrect) {
                if (this.getAttribute('data-correct-message')) {
                    message = button.getAttribute('data-correct-message');
                } else {
                    message = defaultCorrectMessage;
                }

                imgResult = checkImage;
            } else {
                if (this.getAttribute('data-incorrect-message')) {
                    message = button.getAttribute('data-incorrect-message');
                } else {
                    message = defaultIncorrectMessage;
                }

                imgResult = xImage;
            }

            let iconResult;

            if (button.nodeName.toLowerCase() === 'input') {
                // transition code for old buttons
                iconResult = document.createElement('img');
                iconResult.setAttribute('src', imgResult);
                iconResult.style.padding = '0';
                iconResult.style.margin = '0';
                iconResult.style.height = button.style.height;
                iconResult.style.width = button.style.width;
            } else {
                iconResult = document.createElement('span');
                iconResult.style.display = 'inline-block';
                iconResult.style.backgroundSize = 'contain';
                iconResult.style.backgroundRepeat = 'no-repeat';
                iconResult.style.backgroundImage = 'url("' + imgResult + '")';
                iconResult.style.padding = '0';
                iconResult.style.margin = '0';
                iconResult.style.height = button.style.height;
                iconResult.style.width = button.style.width;
                iconResult.style.lineHeight = button.style.lineHeight;
                iconResult.innerHTML = '&nbsp;';
            }

            let parent = button.parentElement;
            let radioButton = button;
            button.remove();

            parent.insertBefore(iconResult, parent.firstChild);

            if (typeof pcuesExtensionInterface !== 'undefined') {
                pcuesExtensionInterface.playText(message);
            }

            setTimeout(function() {
                iconResult.remove();
                parent.insertBefore(radioButton, parent.firstChild);
            }, delaySettings);
        };

        Array.from(choices).forEach(item => {
           item.addEventListener('click', choiceClickHandler);
        });
    });
}

if (typeof window !== 'undefined') {
    window.addEventListener('DOMContentLoaded', function() {
        // letter scope
        let lsIFrame = document.getElementById('letterscope_iframe');
        if (lsIFrame) {
            console.log('LS iframe found.');
            // let urlParams = new URLSearchParams(window.location.search);
            let src = 'https://mymagicladder.org/ml/pls';
            let srcOptions = '?auth_type=pcueswordscope-demo&show_ws_link=0&show_grouped_patterns=0';

            if (DemoModuleUtils.getUrlParameterByName('letter')) {
                src += '/' + DemoModuleUtils.getUrlParameterByName('letter');

                if (DemoModuleUtils.getUrlParameterByName('pattern')) {
                    src += '/' + DemoModuleUtils.getUrlParameterByName('pattern');
                }
            }

            src += srcOptions;

            // lsIFrame.setAttribute('src', src);
            console.log('Setting new iframe source to: ' + src);
            lsIFrame.src = src;
        }

        let paddingTop = parseInt(window.getComputedStyle(document.body, null).getPropertyValue('padding-top'));

        // if there is a hash on page load
        let hash = location.hash;
        hash = hash ? hash.replace('#', '') : '';

        if (hash) {
            console.log('Scrolling a bit by ' + -paddingTop);
            window.scrollBy(0, -paddingTop);
        }

        // hash changes
        window.addEventListener("hashchange", function() {
            console.log('Scrolling a bit.');
            window.scrollBy(0, -paddingTop);
        });

        // back button
        let backButtons = document.getElementsByClassName('browser-back');
        let buttonClickHandler = function(e) {
            e.preventDefault();
            history.go(-1);
        };

        Array.from(backButtons).forEach(function(button) {
            button.classList.add('image-hover');
            button.addEventListener('click', buttonClickHandler);
        });

        // preprocessing css - experimental
        let nodes = document.querySelectorAll('table, img');
        for (let i = 0; i < nodes.length; i++) {
            let node = nodes[i];

            // @todo: convert to regex
            for (let j = 1; j <= 100; j++) {
                if (node.classList.contains('width-' + j)) {
                    node.style.width = j + '%';
                }

                if (node.classList.contains('margin-top-' + j)) {
                    node.style.marginTop = j + '%';
                }

                if (node.classList.contains('margin-right-' + j)) {
                    node.style.marginRight = j + '%';
                }

                if (node.classList.contains('margin-bottom-' + j)) {
                    node.style.marginBottom = j + '%';
                }

                if (node.classList.contains('margin-left-' + j)) {
                    node.style.marginLeft = j + '%';
                }
            }
        }
    });
}

// PQ Quiz
if (typeof window !== 'undefined') {
    window.addEventListener('DOMContentLoaded', function() {
        if (typeof PqQuizSet !== 'undefined') {

            let pqQuizSets = [];
            let pqQuizSetComponents = document.querySelectorAll('.pq-quiz-set');

            for (let i = 0; i < pqQuizSetComponents.length; i++) {
                pqQuizSetComponents[i].setAttribute('id', 'pq-quiz-set-' + i.toString());
                pqQuizSets.push(new PqQuizSet(pqQuizSetComponents[i]));
                pqQuizSets[i].quizSetId = pqQuizSetComponents[i].id;
            }

            document.body.addEventListener('onPcuesExtensionLoaded', function() {
                if (typeof pcuesExtensionInterface !== 'undefined') {
                    Array.from(pqQuizSets).forEach(function(set) {
                        set.setPlayer(pcuesExtensionInterface);
                    });
                }
            });
        }

        // temp location for Gdrive teacher button
        let buttons = document.getElementsByClassName('mystuff-gdrive-link');
        let gDriveBtnClickHandler = function(evt) {
            let button = event.target;

            if (button.hasAttribute('data-short-link') && button.getAttribute('data-short-link').length > 0) {
                LocalStorageWrapper.set('googleDriveLink', evt.target.getAttribute('data-short-link'));
            } else if (button.hasAttribute('data-link') && button.getAttribute('data-link').length > 0) {
                LocalStorageWrapper.set('googleDriveLink', evt.target.getAttribute('data-link'));
            }

            LocalStorageWrapper.set('isMyStuffSourceLocked', 'true');

            window.alert('MyStuff Source Gdrive has been updated.');
        };

        Array.from(buttons).forEach(btn => btn.onclick = gDriveBtnClickHandler);

    });
}
